<template>
  <div class="articlesadd-wrap">
    <div class="add-title">
      <form id="backform" method="post" action="article-questions.php"><input type="hidden" name="article_id" value="<?php echo $id?>"/></form>
      <a class="add-back" @click="back">Revise</a>
      Hi, post your awesome findings to others!
    </div>
    <div class="add-step">
      <ul>
        <li class="cur angle">1. Add a New Article</li>
        <li class="cur angle">2. Add Specific Questions</li>
        <li class="cur">3. Preview</li>
      </ul>
    </div>
    <div class="add-box preview">
      <div class="articles-wrap">
        <div class="articles-banner">
          <h1>{{ newArticle.name }}</h1>
          <div class="ab-from">From {{ newArticle.source }}</div>
          <div class="ab-info">
            <span>{{ newArticle.category }}</span>
            <span>{{ newArticle.topic }}</span>
            <span>{{ newArticle.readingTimes }} mins</span>
          </div>
          <div class="ab-url">
            <a :href="newArticle.link" target="_blank">{{ newArticle.link }}</a>
          </div>
        </div>
      </div>
      <div class="ad-title">General Questions</div>
      <div class="ad-item" v-for="(question, index) in generalQuestions" :key="index">
        <div class="adi-number">{{ index + 1 }}</div>
        <div class="adi-text">
          <div class="adi-title">{{ question.title }}</div>
          <div class="adi-con">
            <label class="adi-label" v-for="(option, idx) in question.options" :key="idx">
              <input type="radio" :name="'t0' + (index + 1)" class="adi-radio" :value="idx + 1"> {{ option }}
            </label>
          </div>
        </div>
      </div>
      <div class="ad-title">Specific Questions</div>
      <div class="ad-box">
        <div class="ad-item" v-for="(newQuestion, index) in newQuestions" :key="index">
          <div class="adi-number">{{ index + 1 }}</div>
          <div class="adi-text">
            <div class="adi-title">{{ newQuestion[0] }}</div>
            <div class="adi-con">
              <label class="adi-label-block" v-for="(option, idx) in newQuestion.slice(1, 5)" :key="idx">
                <input type="radio" :name="'q' + (index + 1)" class="adi-radio" :value="idx + 1"> {{ option }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="add-btn">
      <a class="form-btn" @click="submit">Submit</a>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { get, post } from "@/api";
import { ElMessage, ElMessageBox, ElLoading } from "element-plus";

export default {
  name: "PreviewAdd",
  setup() {
    const newArticle = ref(new Array(8).fill(""));
    const newQuestions = ref([]);
    const generalQuestions = ref([
      { title: "How much do you like the article?", options: ["Not at all", "Not very much", "Somewhat", "Very much"] },
      { title: "How useful do you find this article?", options: ["Not at all", "Not very much", "Somewhat", "Very much"] },
      { title: "How accurate do you think this article is?", options: ["Fake news", "Significantly inaccurate/misleading", "Mostly accurate", "Accurate"] },
      { title: "How confident do you feel about your accuracy judgment?", options: ["Not confident", "Educated guess", "Fairly confident", "Very confident"] },
      { title: "How much did your prior view on this subject change?", options: ["Not at all", "Not very much", "Fairly", "Completely"] },
      { title: "How clearly written do you think this article is?", options: ["Not at all", "Not very clear", "Fairly clear", "Very clear"] },
      { title: "Please provide any additional comments", options: [] }
    ]);
    const router = useRouter();
    const uid = ref(-1);
    const uname = ref("");
    const msg = ref("");
    const reviewStatus = ref(null);

    if (sessionStorage.getItem("token")) {
      post(`/user/logged`).then(res => {
        if (res.res == 1) {
          uid.value = res.user.uid;
          uname.value = res.user.uname;
        } else {
          sessionStorage.removeItem("token");
          sessionStorage.removeItem('Authorization');
        }
      });
    }

    load();
    function load() {
      reviewStatus.value = sessionStorage.getItem("reviewStatus");
      console.log('review Status'+reviewStatus.value);
      if (sessionStorage.getItem("newArticle")) {
        newArticle.value = JSON.parse(sessionStorage.getItem("newArticle"));
        console.log('Loaded newArticle from sessionStorage:', newArticle.value);
      }
      let i = 0;
      while (sessionStorage.getItem("newQuestion" + i)) {
        newQuestions.value.push(JSON.parse(sessionStorage.getItem("newQuestion" + i)));
        i += 1;
      }
      console.log(newQuestions.value)
    }

    function back() {
      router.push("/questions-add");
    }

    function submit() {
      if (!sessionStorage.getItem("revise")) {
        ElMessage({
          message: 'Nothing changed.',
          type: 'success',
        });
        setTimeout(() => {
          router.push({ path: "/account/articles", query: { uid: uid.value } });
        }, 1000);
        return;
      }

      ElMessageBox.confirm(
        'You are submitting your new/revised article & Questions. Continue?',
        'Notice',
        {
          confirmButtonText: 'Continue',
          cancelButtonText: 'Cancel',
          type: 'info'
        }
      ).then(() => {
        const loading = ElLoading.service({
          lock: true,
          text: 'Submitting...',
          background: 'rgba(0, 0, 0, 0.7)',
        });

        // Map your newQuestions to the expected format
        const questionsData = newQuestions.value.map(q => ({
          id: q[6], // Assuming q[6] is the question ID, -1 if new
          question: q[0],
          options: q.slice(1, 5),
          correctChoice: q[5],
          points: 2,
          articleId: newArticle.value.id
        }));

        const endpoint = reviewStatus.value === "change" ? '/question/modify-question' : '/question/save';

        post(endpoint, { questions: questionsData })
          .then(() => {
            ElMessage({
              type: 'success',
              message: 'Questions submitted successfully!',
            });
            clearSessionStorage();
            setTimeout(() => {
              router.push({ path: "/account/articles", query: { uid: uid.value } });
            }, 2000);
          })
          .catch(error => {
            console.error("An error occurred during submission", error);
            ElMessage({
              type: 'error',
              message: 'An error occurred. Please try again.',
            });
          })
          .finally(() => {
            loading.close();
          });
      }).catch(() => {
        ElMessage({
          type: 'info',
          message: 'Canceled',
        });
      });
    }

    function clearSessionStorage() {
      sessionStorage.removeItem("newArticle");
      sessionStorage.removeItem("revise");
      sessionStorage.removeItem("articleId");
      sessionStorage.removeItem("reviewStatus");
      let i = 0;
      while (sessionStorage.getItem("newQuestion" + i)) {
        sessionStorage.removeItem("newQuestion" + i);
        i++;
      }
    }


    return {
      load,
      back,
      submit,
      newArticle,
      newQuestions,
      generalQuestions
    };
  }
}
</script>

<style scoped>
.articlesadd-wrap {
  display: block;
  width: 1100px;
  margin: 0 auto;
}
.add-title {
  display: flex;
  height: 126px;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #14279b;
  font-family: "Poppins-Bold";
  position: relative;
}
.add-back {
  position: absolute;
  left: 0;
  top: 22px;
  width: 93px;
  height: 35px;
  padding: 0 15px 0 38px;
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #14279b;
  border-radius: 18px;
  border: 2px solid #111880;
  font-family: "Poppins-SemiBold";
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
  background: no-repeat 12px center;
  background-size: 19px 19px;
}
.add-back:hover,
.add-save:hover {
  color: #0e1792;
  border: 2px solid #0e1792;
  box-shadow: 0px 2px 20px 0px rgba(59, 93, 245, 0.5);
}
.add-step {
  display: block;
  width: 100%;
  height: 45px;
  border-radius: 26px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
}
.add-step ul {
  display: flex;
}
.add-step li {
  width: 33.33%;
  height: 45px;
  line-height: 45px;
  font-size: 14px;
  color: #14279b;
  font-family: "Poppins-Bold";
  text-align: center;
  background: no-repeat 88% center;
  background-size: 24px 25px;
}
.add-step li.cur {
  color: #ffffff;
  border-radius: 0 23px 23px 0;
  background: #5473ff no-repeat 88% center;
  background-size: 24px 25px;
}
.add-step li.cur.angle {
  border-radius: 0;
}
.add-box {
  display: block;
  width: 100%;
  margin-top: 30px;
  padding: 45px 80px 30px 80px;
  height: auto;
  border-radius: 30px;
  margin-bottom: 15px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
}
.add-box.preview {
  padding: 0 32px 20px 32px;
}
.add-box.preview .articles-wrap {
  width: 100%;
}
.add-box.preview .articles-banner {
  width: 100%;
  background-color: #98ca9f;
}
.add-box h2 {
  display: block;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 24px;
  font-family: "Poppins-Bold";
  color: #14279b;
}
.articles-wrap {
  display: block;
  width: 1100px;
  margin: 0 auto 0 auto;
  padding-bottom: 10px;
}
.articles-banner {
  display: block;
  margin: 0 auto;
  width: 1260px;
  height: 180px;
  padding: 25px 0;
  color: #ffffff;
  background-color: #5473ff;
  text-align: center;
  border-radius: 0 0 80px 80px;
}
.articles-banner h1 {
  font-size: 22px;
  font-family: "Poppins-Bold";
}
.articles-banner .ab-from {
  margin-top: 16px;
  font-size: 20px;
  font-style: italic;
  color: #e8e8e8;
}
.articles-banner .ab-info {
  display: flex;
  margin-top: 15px;
  font-size: 13px;
  justify-content: center;
  align-items: center;
}
.articles-banner .ab-info span {
  display: flex;
  align-items: center;
  margin: 0 18px;
}
.articles-banner .ab-info span img {
  margin-right: 6px;
  width: 19px;
  height: 19px;
}
.articles-banner .ab-url {
  font-size: 16px;
  margin-top: 22px;
}
.articles-banner .ab-url a {
  text-decoration: underline;
  color: #ffffff;
}
.articles-banner .ab-url a:hover {
  color: #d0f2fc;
}
.ad-title {
  display: flex;
  text-align: center;
  font-size: 24px;
  color: #14279b;
  font-family: "Poppins-Bold";
  margin-top: 35px;
  margin-bottom: 35px;
  align-items: center;
  justify-content: center;
}
.ad-title img {
  width: 29px;
  height: 29px;
  margin-right: 10px;
}
.ad-item {
  display: flex;
  padding: 35px 0;
  border-bottom: 1px solid #e5e5e5;
}
.ad-item:last-child {
  border-bottom: none;
}
.ad-item .adi-number {
  flex: 0 0 8%;
  width: 8%;
  display: flex;
  align-items: center;
  font-size: 32px;
  color: #969696;
  font-family: "Poppins-Bold";
}
.ad-item .adi-text {
  flex: 0 0 92%;
  width: 92%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.ad-item .adi-title {
  font-size: 16px;
  color: #000000;
  font-family: "Poppins-Bold";
  margin-bottom: 22px;
}
.ad-item .adi-con {
  display: block;
  width: 100%;
  font-size: 14px;
}
.ad-item .adi-label {
  display: inline-block;
  font-size: 14px;
  padding-right: 4%;
}
.ad-item .adi-label-block {
  display: block;
  width: 100%;
  font-size: 14px;
  padding-right: 0;
  margin: 10px 0;
  white-space: pre-wrap;
  word-break: break-word;
}
.ad-item .adi-label .adi-radio,
.ad-item .adi-label-block .adi-radio {
  width: 19px;
  height: 19px;
  vertical-align: -4px;
  margin-right: 12px;
}
.add-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 175px;
  padding-bottom: 30px;
  position: relative;
}
.add-btn .submit-tips {
  bottom: 150px;
  height: 130px;
}
.ad-textarea {
  width: 80%;
  font-size: 15px;
  padding: 20px 20px;
  background-color: #eff1f5;
  border: none;
  border-radius: 15px;
  min-height: 100px;
}
.form-btn {
  display: block;
  width: 180px;
  height: 50px;
  font-size: 20px;
  color: #fff !important;
  line-height: 50px;
  text-align: center;
  border: none;
  margin: 0 auto;
  border-radius: 25px;
  cursor: pointer;
  font-family: "Poppins-Black";
  background-color: #5473ff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
}
.form-btn:hover {
  background-color: #4261f0;
  box-shadow: 0px 2px 20px 0px rgba(59, 93, 245, 0.5);
}
</style>
